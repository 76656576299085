.note-input {
  position: absolute;
  top: 50%;
  transform: translate(-0%, -50%);
}

.note-button {
  text-align: left;
  height: 18px;
}

.rs-notification-content {
  max-width: 600px !important;
}
